var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SlModal',{attrs:{"id":_vm.id,"title":_vm.title,"persistent":""},on:{"created":_vm.onCreated,"on-enter":_vm.handleApply,"hide":_vm.onModalHide},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('SlModalFooter',[_c('SlButton',{attrs:{"variant":"secondary","color":"grey"},on:{"click":function($event){return _vm.hideModal(_vm.id)}}},[_vm._v(" "+_vm._s(_vm.$t('Common.Cancel'))+" ")]),_c('SlButton',{on:{"click":_vm.handleApply}},[_vm._v(" "+_vm._s(_vm.primaryButtonText)+" ")])],1)]},proxy:true}])},[_c('ValidationObserver',{ref:"observer",staticClass:"modal-content forecast-versions-form"},[(!_vm.isFinalForecast && !_vm.isStatisticalForecast)?_c('SlValidate',{attrs:{"vid":"name","mode":"eager","rules":_vm.nameRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.Table.Header.Name'),"is-invalid":invalid,"required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,false,2541377767)}):_vm._e(),_c('SlSelect',{attrs:{"select-label":_vm.$t('Web.CustomFields.Type'),"options":_vm.forecastVersionTypeOptions,"disabled":_vm.isEditMode},model:{value:(_vm.fieldType),callback:function ($$v) {_vm.fieldType=$$v},expression:"fieldType"}}),_c('SlControl',{attrs:{"options":_vm.permissionOptions,"label":_vm.$t('Web.CustomFields.Viewers')},model:{value:(_vm.viewType),callback:function ($$v) {_vm.viewType=$$v},expression:"viewType"}}),_c('transition-expand',[(_vm.viewType === _vm.forecastVersionAccessTypes.SOME)?_c('div',[_c('SlSelect',{attrs:{"options":_vm.specificUsersOptions,"multiple":"","allow-empty":"","clearable":"","close-on-select":false,"placeholder":_vm.$t('Web.CustomFields.InputPlaceholder'),"group-values":"options","group-label":"group","track-by":"id","label":"name"},scopedSlots:_vm._u([{key:"selectOption",fn:function(ref){
var option = ref.option;
var selected = ref.selected;
return [(option.$groupLabel)?[_c('p',{staticClass:"forecast-versions__select-header"},[_vm._v(" "+_vm._s(option.$groupLabel)+" ")])]:(option.name)?[_c('div',{staticClass:"forecast-versions__select-option"},[(option.profilePictureUrl)?_c('img',{staticClass:"forecast-versions__select-image",attrs:{"referrerpolicy":"no-referrer","src":option.profilePictureUrl}}):_c('icon',{staticClass:"fill-off size-20 forecast-versions__select-image",attrs:{"data":require("@icons/roles_users.svg")}}),_vm._v(" "+_vm._s(option.name)+" ")],1)]:_vm._e(),(selected)?_c('icon',{staticClass:"fill-off size-16 color-primary-80 check-icon",attrs:{"data":require("@icons/check.svg")}}):_vm._e()]}},{key:"tag",fn:function(ref){
var option = ref.option;
var remove = ref.remove;
return [_c('div',{staticClass:"forecast-versions__select-tag"},[(option.profilePictureUrl)?_c('img',{staticClass:"forecast-versions__select-image forecast-versions__select-image--small",attrs:{"referrerpolicy":"no-referrer","src":option.profilePictureUrl}}):_c('icon',{staticClass:"fill-off size-18 forecast-versions__select-image",attrs:{"data":require("@icons/roles_users.svg")}}),_c('div',{staticClass:"forecast-versions__select-tag-text"},[_vm._v(" "+_vm._s(option.name)+" ")]),_c('icon',{staticClass:"fill-off size-18 ml-4",attrs:{"data":require("@icons/close.svg")},on:{"click":function($event){return remove(option)}}})],1)]}}],null,false,3447600458),model:{value:(_vm.viewers),callback:function ($$v) {_vm.viewers=$$v},expression:"viewers"}})],1):_vm._e()]),(!_vm.isStatisticalForecast)?_c('SlControl',{attrs:{"options":_vm.permissionOptions,"label":_vm.$t('Web.CustomFields.Editors')},model:{value:(_vm.editType),callback:function ($$v) {_vm.editType=$$v},expression:"editType"}}):_vm._e(),_c('transition-expand',[(_vm.editType === _vm.forecastVersionAccessTypes.SOME)?_c('div',[_c('SlSelect',{attrs:{"options":_vm.specificUsersOptions,"multiple":"","allow-empty":"","clearable":"","close-on-select":false,"placeholder":_vm.$t('Web.CustomFields.InputPlaceholder'),"group-values":"options","group-label":"group","track-by":"name","label":"name"},scopedSlots:_vm._u([{key:"selectOption",fn:function(ref){
var option = ref.option;
var selected = ref.selected;
return [(option.$groupLabel)?[_c('p',{staticClass:"forecast-versions__select-header"},[_vm._v(" "+_vm._s(option.$groupLabel)+" ")])]:(option.name)?[_c('div',{staticClass:"forecast-versions__select-option"},[(option.profilePictureUrl)?_c('img',{staticClass:"forecast-versions__select-image",attrs:{"referrerpolicy":"no-referrer","src":option.profilePictureUrl}}):_c('icon',{staticClass:"fill-off size-20 forecast-versions__select-image",attrs:{"data":require("@icons/roles_users.svg")}}),_vm._v(" "+_vm._s(option.name)+" ")],1)]:_vm._e(),(selected)?_c('icon',{staticClass:"fill-off size-16 color-primary-80 check-icon",attrs:{"data":require("@icons/check.svg")}}):_vm._e()]}},{key:"tag",fn:function(ref){
var option = ref.option;
var remove = ref.remove;
return [_c('div',{staticClass:"forecast-versions__select-tag"},[(option.profilePictureUrl)?_c('img',{staticClass:"forecast-versions__select-image forecast-versions__select-image--small",attrs:{"referrerpolicy":"no-referrer","src":option.profilePictureUrl}}):_c('icon',{staticClass:"fill-off size-18 forecast-versions__select-image",attrs:{"data":require("@icons/roles_users.svg")}}),_c('div',{staticClass:"forecast-versions__select-tag-text"},[_vm._v(" "+_vm._s(option.name)+" ")]),_c('icon',{staticClass:"fill-off size-18 ml-4",attrs:{"data":require("@icons/close.svg")},on:{"click":function($event){return remove(option)}}})],1)]}}],null,false,3447600458),model:{value:(_vm.editors),callback:function ($$v) {_vm.editors=$$v},expression:"editors"}})],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }